import { Navigate } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { useIAnav } from "util/feature_detection";
import { useFeatureFlag } from "common/feature_gating";
import { TRANSACTION_PATH } from "util/routes";
import { HIDE_GET_STARTED } from "constants/feature_gates";

import { Root } from "../common/root";
import { Header } from "../common/header";
import { TitleWorkflows, ResourcesSection } from "../common/section";
import TitleGetStartedQuery from "./title_get_started_query.graphql";

export type NotaryInfo = {
  state: "incomplete" | "needs_review" | "compliant" | "not_compliant";
  trainingEnabled: boolean;
};

export function TitleGetStarted() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(TitleGetStartedQuery, {
    variables: { orgId: activeOrganizationId! },
  });
  const isIaNav = useIAnav();
  const hideGetStarted = useFeatureFlag(HIDE_GET_STARTED);

  if (hideGetStarted || (!isIaNav && !loading)) {
    return <Navigate to={TRANSACTION_PATH} replace />;
  }

  if (loading || !isIaNav) {
    return <LoadingIndicator />;
  }

  if (data?.organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${data?.organization?.__typename}.`);
  }

  const organization = data.organization;

  return (
    <div>
      <Header isTitle organizationId={organization.id} />
      <Root>
        <TitleWorkflows />
        <ResourcesSection />
      </Root>
    </div>
  );
}
